import { Auth } from "aws-amplify";
import moment from "../common/UserMoment";
import { ActionCreators } from "../actions/profile";
import { _callApi } from "../Services/baseService";
const IDEAL_TIMEOUT = Number(process.env.REACT_APP_IDEAL_TIMEOUT);

export const getProviderRoles = (
  parentC,
  branch,
  profile,
  dispatch,
  isCNEnabled = false
) => {
  return new Promise((resolve, reject) => {
    if (isCNEnabled) {
      let roles = [];
      if (
        ["SYSTEM ADMINISTRATOR", "SYSTEM_ADMINISTRATOR", "LOCAL_ADMIN"].some(
          (a) => [...profile.profile.split(",")].includes(a)
        )
      ) {
        roles.push("sysadmin");
      }
      if (
        ["CARE_NAVIGATOR"].some((a) =>
          [...profile.profile.split(",")].includes(a)
        )
      ) {
        roles.push("Monitor");
      }
      if (["REPORT"].some((a) => [...profile.profile.split(",")].includes(a))) {
        roles.push("report");
      }
      dispatch(ActionCreators.setUserRoles(roles));
      dispatch(ActionCreators.setConfig(branch, parentC));
      resolve(roles);
    } else {
      let profileData = profile.profile.split(",").map((p) => {
        return "profile=" + p;
      });
      let data = {};
      let headerdata = "";

      _callApi(
        data,
        "REACT_APP_CLINIC_ROLES",
        headerdata,
        2,
        parentC +
          "/" +
          branch +
          "/profile?therapistId=" +
          profile.id +
          "&" +
          profileData?.join("&")
      )
        .then((response) => {
          if (response.status === 200) {
            if (
              response?.data.length > 0 &&
              response?.data[0].roles.length > 0
            ) {
              let roles = response.data[0]?.roles
                ? [...response.data[0].roles]
                : [];

              if (
                [
                  "SYSTEM ADMINISTRATOR",
                  "SYSTEM_ADMINISTRATOR",
                  "LOCAL_ADMIN",
                ].some((a) => [...profile.profile.split(",")].includes(a))
              ) {
                roles.push("sysadmin");
              }
              if (
                ["CARE_NAVIGATOR"].some((a) =>
                  [...profile.profile.split(",")].includes(a)
                )
              ) {
                if (!roles.includes("Monitor")) {
                  roles.push("Monitor");
                }
                // if (!roles.includes("Enroll")) {
                //   roles.push("Enroll");
                // }
              }
              if (
                [...profile.profile.split(",")]
                  .map((profile) => profile.trim())
                  .includes("REPORT")
              ) {
                roles.push("report");
              }
              dispatch(ActionCreators.setUserRoles(roles));
              dispatch(ActionCreators.setConfig(branch, parentC));
              resolve(roles);
            } else {
              let roles = [];

              if (
                [
                  "SYSTEM ADMINISTRATOR",
                  "SYSTEM_ADMINISTRATOR",
                  "LOCAL_ADMIN",
                ].some((a) => [...profile.profile.split(",")].includes(a))
              ) {
                roles.push("sysadmin");
              }
              if (
                ["CARE_NAVIGATOR"].some((a) =>
                  [...profile.profile.split(",")].includes(a)
                )
              ) {
                roles.push("Monitor");
              }
              if (
                ["REPORT"].some((a) =>
                  [...profile.profile.split(",")].includes(a)
                )
              ) {
                roles.push("report");
              }
              if (roles?.length > 0) {
                dispatch(ActionCreators.setUserRoles(roles));
                dispatch(ActionCreators.setConfig(branch, parentC));
                resolve(roles);
              } else {
                reject("No roles found");
              }
            }
          } else {
            reject("Error fetching roles");
          }
        })
        .catch((err) => {
          reject(err);
        });
    }
  });
};

export const postSessionInfo = (
  durationInSeconds,
  clinicId,
  therapistId,
  isStartTime,
  uuid,
  hadIdealTime
) => {
  return new Promise((resolve, reject) => {
    let endTime;
    let startTime;
    if (!isStartTime) {
      if (hadIdealTime) {
        endTime = moment()
          .utc()
          .subtract(IDEAL_TIMEOUT / 60000, "minutes")
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      } else {
        endTime = moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      }
    } else {
      endTime = null;
    }
    if (isStartTime) {
      startTime = moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    } else {
      startTime = null;
    }
    let data = {
      clinicId: clinicId,
      duration: durationInSeconds,
      endTime: endTime,
      sessionId: uuid,
      startTime: startTime,
    };
    let headerdata = "";
    _callApi(
      data,
      "REACT_APP_GET_THERAPIST_URL",
      headerdata,
      1,
      "/" + therapistId + "/sessioninfo"
    )
      .then((response) => {
        if (response?.status === 200) {
          resolve(response.data);
        } else {
          // reject(new Error("Session info not saved"));
          resolve("Session info not saved");
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const logoutAndRedirect = (
  profile,
  dispatch,
  tabUid,
  sessionDuration,
  hadIdealTime = false
) => {
  return new Promise((resolve, reject) => {
    if (profile.email !== undefined) {
      if (profile?.profile.includes("CARE_NAVIGATOR")) {
        //CARE_NAVIGATORS
        dispatch(ActionCreators.stopCNInterval());
        let loginTime = sessionDuration?.loginTime;
        let logoutTime = moment();
        const durationInSeconds = logoutTime.diff(loginTime, "seconds");
        postSessionInfo(
          durationInSeconds,
          sessionDuration?.clinicId,
          profile?.id,
          false,
          sessionDuration?.uuid,
          hadIdealTime
        )
          .then(() => {
            Auth.signOut({ global: true });
            dispatch(ActionCreators.resetStore(tabUid));
            resolve();
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        Auth.signOut({ global: true });
        dispatch(ActionCreators.resetStore(tabUid));
        resolve();
      }
    }

    let data = localStorage.getItem("browser-tab");
    localStorage.clear();
    localStorage.setItem("browser-tab", data);
  });
};
export const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};

export const EllipsisText = ({ text, maxLength }) => {
  return (
    <div
      className="ellipsis-text"
      title={text?.length > maxLength ? text : null}
    >
      {text?.length > maxLength ? `${text?.slice(0, maxLength)}...` : text}
    </div>
  );
};
export const convertSecondsToTime = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  const paddedMinutes = minutes.toString().padStart(2, "0");
  const paddedSeconds = seconds.toString().padStart(2, "0");

  return `${hours}:${paddedMinutes}:${paddedSeconds}`;
};

export const checkTokenValidity = (dispatch, sessionGuid) => {
  const splitToken = localStorage.getItem("accessToken")?.split(".");
  const clearLocalStorageAndResetStore = () => {
    let data = localStorage.getItem("browser-tab");
    localStorage.clear();
    localStorage.setItem("browser-tab", data);
    if (sessionGuid) {
      dispatch(ActionCreators.resetStore(sessionGuid));
    }
  };
  if (splitToken && splitToken.length === 3) {
    try {
      const decodeToken = atob(splitToken[1]);
      const tokenObj = JSON.parse(decodeToken);
      const currentTime = Math.floor(Date.now() / 1000);
      if (tokenObj.exp > currentTime) {
        return;
      } else {
        clearLocalStorageAndResetStore();
      }
    } catch (error) {
      console.error("Failed to decode token:", error);
      clearLocalStorageAndResetStore();
    }
  } else {
    //console.error("Access token is missing or malformed");
    clearLocalStorageAndResetStore();
  }
};
export const getOrphanPatient = (
  parentC,
  branchs,
  pageNo,
  systemAdmin = false
) => {
  return new Promise((resolve, reject) => {
    let branchData = branchs?.map((p) => {
      return "branchId=" + p.clinicId;
    });
    let data = {};
    let headerdata = "";

    _callApi(
      data,
      "REACT_APP_CLINIC_ROLES",
      headerdata,
      2,
      "patient/" +
        parentC +
        "/orphan?systemAdmin=" +
        systemAdmin +
        "&pageNo=" +
        pageNo +
        "&pageSize=5" +
        "&" +
        branchData?.join("&")
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response?.data);
        } else {
          reject("Error fetching data");
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const getUniRequestsById = (therapistId, pageNo) => {
  return new Promise((resolve, reject) => {
    let data = {};
    let headerdata = "";

    _callApi(
      data,
      "REACT_APP_CLINIC_ROLES",
      headerdata,
      2,
      `${therapistId}/customapprovals?pageNo=${pageNo}&pageSize=5`
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response?.data);
        } else {
          reject("Error fetching data");
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
