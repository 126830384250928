import { DASHBOARD } from "src/common/roles";
import Moment from "../common/UserMoment";
import Cookies from "js-cookie";
/**
 * Set localStorage
 */
export const setStore = (name, content) => {
  if (!name) return;
  if (typeof content !== "string") {
    content = JSON.stringify(content);
  }
  return window.localStorage.setItem(name, content);
};

/**
 * Get localStorage
 */
export const getStore = (name) => {
  if (!name) return;
  return JSON.parse(window.localStorage.getItem(name));
};

/**
 * Clear localStorage
 */
export const removeItem = (name) => {
  if (!name) return;
  return window.localStorage.removeItem(name);
};

/**
 * Validate Email
 */
export const isValidEmail = (value) => {
  return !(value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,64}$/i.test(value));
};

/**
 * Format Phone Number
 */
export const formatPhoneNumber = (value) => {
  if (value === "") return "";
  if (!value) return;
  const currentValue = value.replace(/[^\d]/g, "");
  const mobileNoLength = currentValue.length;
  if (mobileNoLength >= 4) {
    if (mobileNoLength < 4) return currentValue;
    if (mobileNoLength < 7)
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  } else {
    return currentValue;
  }
};
export const removePlusOnePrefix = (value) => {
  if (!value) return "";
  if (value.startsWith("+1 ")) {
    return value.slice(3);
  }

  return value;
};

// Set Query Param
export const setQueryParams = (queryParams, key, value) => {
  let search = queryParams?.substr(1);
  let split = search?.split(key) || [];
  let first = split?.[1]?.split("&") || ["", ""];
  first[0] = `=${value}`;
  first = first?.join("&");
  split[1] = first;
  search = split.join(key);
  return search;
};

export const getDashboard = (userRoles) => {
  let dashIndex = DASHBOARD.filter((dash) =>
    userRoles?.includes(dash.role)
  ).reduce((acc, curr) => {
    if (acc.weight <= curr.weight) {
      return acc;
    } else {
      return curr;
    }
  }, {});
  if (dashIndex.dashboard) {
    return dashIndex.dashboard;
  } else {
    return "/dashboard";
  }
};
export const truncate = (str, n) => {
  return str?.length > n ? str.substr(0, n - 1) + "..." : str;
};
export const displayPhoneNumber = (number) => {
  const numericNumber = number.replace(/\D/g, "");
  if (numericNumber.length === 10) {
    // Format the number as (XXX) XXX-XXXX
    const formattedNumber = `(${numericNumber.substring(
      0,
      3
    )}) ${numericNumber.substring(3, 6)}-${numericNumber.substring(6)}`;
    return formattedNumber;
  } else {
    return null;
  }
};

export const formatDuration = (secs, hideHours = false) => {
  if (Number.isNaN(secs)) {
    return hideHours ? "00:00" : "00:00:00"; // Set default values for NaN case
  } else {
    const hours = Math.floor(secs / 3600);
    const minutes = Math.floor((secs % 3600) / 60);
    const seconds = Math.floor(secs % 60);

    if (hideHours) {
      return `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    } else {
      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    }
  }
};

export const formatStrInputVal = (str) => {
  const trimmedValue = str.trimStart(); // Trim spaces from the left side
  const spaceCount = (trimmedValue.match(/ /g) || []).length; // Count the number of spaces
  let val = str;

  if (spaceCount > 10) {
    let count = 0;
    val = trimmedValue.replace(/ /g, (match) => {
      count++;
      return count === spaceCount ? "" : match;
    });
  }
  // Ensure there is at most one consecutive space
  val = val.replace(/ {2,}/g, " ");

  if (trimmedValue.length !== str.length) {
    val = trimmedValue; // Remove the space at the beginning
  }
  const alphanumericVal = val.replace(/[^A-Za-z.,\s-]/g, ""); // Remove non-alphanumeric characters except ".", "-", and ","

  return alphanumericVal;
};
export const formatOffsetHours = (dt) => {
  const offsetHours = Moment().utcOffset() / 60;
  const date = new Date(dt);
  const utcTime = date.getTime() - offsetHours * 60 * 60 * 1000;
  const adjustedDate = new Date(utcTime);
  const isoString = adjustedDate.toISOString();

  return isoString;
};
export const hexToRgba = (hexColor, opacity) => {
  hexColor = hexColor.replace("#", "");
  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};
export const updateFaviconUrl = (newFaviconUrl) => {
  // const favicon = document.querySelector("link[rel~='icon']");
  // if (favicon) {
  //   favicon.href = newFaviconUrl;
  // }
  const head = document.querySelector("head");
  const existingFavicon = document.querySelector("link[rel~='icon']");
  if (existingFavicon) {
    head.removeChild(existingFavicon);
  }
  const newFavicon = document.createElement("link");
  newFavicon.rel = "shortcut icon";
  newFavicon.href = newFaviconUrl;
  head.appendChild(newFavicon);
};
export const applySkinColor = (config) => {
  const colorPrimary = config?.skin?.BrandColor;
  const faviconUrl = config?.skin?.favicon;
  const logo = config?.logo;
  if (faviconUrl !== undefined && faviconUrl !== "") {
    updateFaviconUrl(faviconUrl);
  }
  if (colorPrimary !== undefined && colorPrimary !== "") {
    const storedSkin = Cookies.get("skin");
    if (storedSkin !== colorPrimary) {
      Cookies.set("skin", colorPrimary, { expires: 1 });
    }
    const colorPrimary_light = hexToRgba(colorPrimary, 0.1);
    document.documentElement.style.setProperty("--primaryColor", colorPrimary);
    document.documentElement.style.setProperty(
      "--primaryColor-light",
      colorPrimary_light
    );
  }
  if (logo !== undefined || logo !== "") {
    const storedLogo = Cookies.get("logo");
    if (storedLogo !== logo) {
      Cookies.set("logo", logo, { expires: 7 });
    }
  }
};

export const getFormatDateMMDD = (datanew) => {
  const newDateToassign = new Date(datanew);

  // Extracting the date components
  const year = newDateToassign.getFullYear();
  const month = (newDateToassign.getMonth() + 1).toString().padStart(2, "0");
  const day = newDateToassign.getDate().toString().padStart(2, "0");

  return `${month}/${day}/${year}`;
};
