import { postSessionInfo } from "src/utils/common";
import { Types } from "../constants/actionTypes";

const initialState = {
  profile: {},
  formSubmitted: false,
  timeSpentResponse: {
    success: null,
    error: null,
    action: null,
  },
  currentClinicID: null,
  currentParentClinicID: null,
  currentClinicTZ: null,
  // currentClinicID: localStorage.getItem("UserClinic") || null,
  // currentParentClinicID: localStorage.getItem("UserParentClinic") || null,
  // currentClinicTZ: localStorage.getItem("UserTimeZone") || null,
  clinicsInfo: [],
  userRoles: [],
  stopWatchTimerIsRunning: false,

  config: {},
  stopWatchTimer: {
    seconds: 0,
    minutes: 0,
    hours: 0,
    days: 0,
    isRunning: false,
  },
  patExerciseModified: {},
  patExeModiNotify: false,
  tabUid: null,
  sessionDuration: {
    loginTime: null,
    logoutTime: null,
    isLoggedIn: false,
    clinicId: null,
    uuid: null,
  },
  timerOut: null,
  cnIntervalId: null,
  isOrphanOpen: false,
  isCareNavigator: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.LOGIN:
      return {
        ...state,
        profile: action.payload.user,
        formSubmitted: false, // after update user formsubmition reset
      };

    case Types.ADD_PATIENT:
      return {
        ...state,
        patient: action.payload.patient,
        formSubmitted: false, // after update user formsubmition reset
      };
    // file deepcode ignore DuplicateCaseBody: <please specify a reason of ignoring this>
    case Types.ADD_USER:
      return {
        ...state,
        profile: action.payload.user,
        formSubmitted: false, // after update user formsubmition reset
      };
    case Types.UPDATE_USER:
      return {
        ...state,
        profile: action.payload.user,
        formSubmitted: false, // after update user formsubmition reset
      };
    case Types.UPDATE_PROFILE_PICTURE:
      return {
        ...state,
        profile: {
          ...state.profile,
          profileImage: action.payload.image,
        },
      };
    case Types.FORM_SUBMITION_STATUS:
      return {
        ...state,
        formSubmitted: action.payload.status,
      };
    case Types.LOGOUT:
      return {
        ...state,
        profile: {},
        formSubmitted: false,
      };
    case Types.TIME_SPENT:
      return {
        ...state,
        timeSpentResponse: {
          success: action.payload.success,
          error: action.payload.error,
          action: action.payload.action,
        },
      };
    case Types.SET_CURRENT_CLINIC_BRANCH:
      return {
        ...state,
        currentClinicID: action.payload === "null" ? null : action.payload,
      };
    case Types.SET_TIME_ZONE:
      return {
        ...state,
        currentClinicTZ: action.payload === "null" ? null : action.payload,
      };
    case Types.ADD_CLINIC_BRANCHS:
      return {
        ...state,
        clinicsInfo: action.payload,
      };
    case Types.TIMER_IS_RUNNING:
      return {
        ...state,
        stopWatchTimerIsRunning: action.payload,
      };
    case Types.SET_STOPWATCH_TIMER:
      return {
        ...state,
        stopWatchTimer: {
          seconds: action.payload.seconds,
          minutes: action.payload.minutes,
          hours: action.payload.hours,
          days: action.payload.days,
          isRunning: action.payload.isRunning,
        },
      };
    case Types.SET_CURRENT_PARENT_CLINIC:
      return {
        ...state,
        currentParentClinicID:
          action.payload === "null" ? null : action.payload,
      };
    case Types.SET_USER_ROLES:
      return {
        ...state,
        userRoles: action.payload,
      };
    case Types.CONFIG:
      return {
        ...state,
        config: action.payload,
      };
    case Types.RESET_STORE:
      return { ...initialState, tabUid: action.payload };
    case Types.SET_PAT_EXERCISE_MODIFY:
      return {
        ...state,
        patExerciseModified: action.payload,
      };
    case Types.SET_PAT_EXE_MODI_NOTIFY:
      return {
        ...state,
        patExeModiNotify: action.payload,
      };
    case Types.TAB_UID:
      return {
        ...state,
        tabUid: action.payload,
      };
    case Types.SESS_DURATION:
      return {
        ...state,
        sessionDuration: { ...state.sessionDuration, ...action.payload },
      };
    case Types.TIMER_OUT:
      return {
        ...state,
        timerOut: action.payload,
      };

    case Types.START_CN_INTERVAL:
      return {
        ...state,
        cnIntervalId: setInterval(() => {
          postSessionInfo(
            0,
            state.sessionDuration?.clinicId,
            state.profile?.id,
            false,
            state.sessionDuration?.uuid,
            false
          );
        }, action.payload),
      };

    case Types.STOP_CN_INTERVAL:
      clearInterval(state.cnIntervalId);
      return {
        ...state,
        cnIntervalId: null,
      };
    case Types.IS_ORPHAN_OPEN:
      return {
        ...state,
        isOrphanOpen: action.payload,
      };
    case Types.IS_CARE_NAVIGATOR:
      return {
        ...state,
        isCareNavigator: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
