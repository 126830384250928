import { Auth } from "aws-amplify";

export const tokenRefresh = () => {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then((session) => {
        const refreshToken = session.refreshToken;
        Auth.currentAuthenticatedUser()
          .then((CognitoUser) => {
            CognitoUser.refreshSession(refreshToken, (err, data) => {
              if (err) {
                console.log("ErrorTR:", err);
                reject(err);
              } else {
                const accessToken = data.getAccessToken().getJwtToken();
                const idToken = data.getIdToken().getJwtToken();
                localStorage.setItem("idToken", idToken);
                localStorage.setItem("accessToken", accessToken);

                resolve();
              }
            });
          })
          .catch((error) => {
            console.log("ErrorTR:", error);
            reject(error);
          });
      })
      .catch((error) => {
        console.log("ErrorTR:", error);
        reject(error);
      });
  });
};
